import React from 'react';

import { BlogCard, CardInfo, ExternalLinks, GridContainer, HeaderThree, Hr, Tag, TagList, TitleContent, UtilityList, Img } from './ProjectsStyles';
import { Section, SectionDivider, SectionTitle } from '../../styles/GlobalComponents';
import { features } from '../../constants/constants';

const Projects = () => (
  <Section nopadding id="projects">
    <SectionDivider />
    <SectionTitle main>App Features</SectionTitle>
    <GridContainer>
      {features.map((feature, index) => {
        return (
          <BlogCard key={index} style={{padding: '40px'}}>
            <TitleContent>
              <HeaderThree title>{feature.title}</HeaderThree>
              <Hr />
            </TitleContent>
            <CardInfo className="card-info">{feature.description}</CardInfo>
            <div>
              <TitleContent>Tags </TitleContent>
              <TagList>
                {feature.tags.map((tag, index) => {
                  return <Tag key={index}>{tag}</Tag>;
                })}
              </TagList>
            </div>
            <UtilityList>
              {feature.visit && <ExternalLinks href={feature.visit}>Learn More</ExternalLinks>}
            </UtilityList>
          </BlogCard>
        );
      })}
    </GridContainer>
  </Section>
);

export default Projects;
