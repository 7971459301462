import React from 'react';
import { DiFirebase, DiReact, DiZend } from 'react-icons/di';
import { Section, SectionDivider, SectionText, SectionTitle } from '../../styles/GlobalComponents';
import { List, ListContainer, ListItem, ListParagraph, ListTitle } from './TechnologiesStyles';

const Technologies = () => (
  <Section id="tech">
    <SectionDivider divider />
    <SectionTitle>Technologies Behind Lift</SectionTitle>
    <SectionText>
      At Lift, we utilize advanced technologies to provide the only ride-sharing service in Bahidar that ensures a seamless and efficient ride experience. Discover how our innovative solutions set us apart:
    </SectionText>
    <List>
      <ListItem>
        <picture>
          <DiReact size="3rem" />
        </picture>
        <ListContainer>
          <ListTitle>Intuitive Interface</ListTitle>
          <ListParagraph>
            Built with <br />
            Flutter for a responsive and user-friendly interface, making ride booking a breeze.
          </ListParagraph>
        </ListContainer>
      </ListItem>
      <ListItem>
        <picture>
          <DiFirebase size="3rem" />
        </picture>
        <ListContainer>
          <ListTitle>Reliable Backend</ListTitle>
          <ListParagraph>
            Powered by <br />
            Sanity and secure databases ensuring smooth operations and data integrity.
          </ListParagraph>
        </ListContainer>
      </ListItem>
      <ListItem>
        <picture>
          <DiZend size="3rem" />
        </picture>
        <ListContainer>
          <ListTitle>Exceptional Design</ListTitle>
          <ListParagraph>
            Crafted with <br />
            Canva to provide an aesthetically pleasing user experience that enhances usability.
          </ListParagraph>
        </ListContainer>
      </ListItem>
      <ListItem>
        <picture>
          <DiReact size="3rem" />
        </picture>
        <ListContainer>
          <ListTitle>Real-time Features</ListTitle>
          <ListParagraph>
            Utilizing <br />
            Flutter Map for real-time ride tracking, ensuring safety and convenience.
          </ListParagraph>
        </ListContainer>
      </ListItem>
    </List>
    <SectionDivider colorAlt />
  </Section>
);

export default Technologies;