import Link from 'next/link';
import React from 'react';
import { AiFillGithub, AiFillInstagram, AiFillLinkedin, AiFillYoutube } from 'react-icons/ai';
import { FaCar, FaClosedCaptioning, FaLocationArrow, FaMapMarker, FaMapMarkerAlt, FaMarker, FaSearchLocation } from 'react-icons/fa'; // Changed to a car icon to match the ride-sharing theme

import { Container, Div1, Div2, Div3, NavLink, SocialIcons } from './HeaderStyles';

const Header = () =>  (
  <Container>
    <Div1>
      <Link href="/">
        <a style={{ display: 'flex', alignItems: 'center', color:"red" }}>
          <FaMapMarkerAlt size="3rem" /> <span><strong>Lift</strong></span>
        </a>
      </Link>
    </Div1>
    <Div2>
      <li>
        <Link href="#features">
          <NavLink>Features</NavLink>
        </Link>
      </li>
      <li>
        <Link href="#download">
          <NavLink>Download</NavLink>
        </Link>
      </li>        
      <li>
        <Link href="#contact">
          <NavLink>Contact</NavLink>
        </Link>
      </li>        
    </Div2>
    <Div3 style={{ display: 'flex', alignItems: 'center', color:"red" }}>
      <SocialIcons href="https://github.com">
        <AiFillYoutube style={{ color:"red" }} size="3rem" />
      </SocialIcons>
      <SocialIcons href="https://linkedin.com">
        <AiFillLinkedin style={{ color:"red" }} size="3rem" />
      </SocialIcons>
      <SocialIcons href="https://instagram.com">
        <AiFillInstagram style={{ color:"red" }} size="3rem"/>
      </SocialIcons>
    </Div3>
  </Container>
);

export default Header;
