import React from 'react';
import { Section, SectionDivider, SectionTitle } from '../../styles/GlobalComponents';
import { Box, Boxes, BoxNum, BoxText } from './AcomplishmentsStyles';

const data = [
  { number: 500, text: 'Rides Completed' },
  { number: 150, text: 'Active Drivers' },
  { number: 2000, text: 'Registered Users' },
  { number: 4.8, text: 'Average Rating' }
];

const Acomplishments = () => (
  <Section>
    <SectionTitle>Lift Achievements</SectionTitle>
    <Boxes>
      {data.map((card, index) => (
        <Box key={index}>
          <BoxNum>{`${card.number}${card.text === 'Average Rating' ? '' : '+'}`}</BoxNum>
          <BoxText>{card.text}</BoxText>
        </Box>
      ))}
    </Boxes>
    <SectionDivider />
  </Section>
);

export default Acomplishments;