import React from 'react';

import { Section, SectionText, SectionTitle } from '../../styles/GlobalComponents';
import Button from '../../styles/GlobalComponents/Button';
import { LeftSection } from './HeroStyles';

const Hero = (props) => (
  <>
    <Section row nopadding>
      <LeftSection>
        <SectionTitle main center>
          Welcome To <br />
          <strong style={{color: 'red'}}>Lift</strong> Ride-Sharing App
        </SectionTitle>
        <SectionText>
          Experience the best in ride-sharing with Lift. Whether you're heading to work, a night out, or the airport, we've got you covered with reliable and affordable rides.
        </SectionText>
        <Button onClick={props.handleClick}>Get Started</Button>
      </LeftSection>
    </Section>
  </>
);

export default Hero;
