

export const features = [
  {
    title: 'Real-time Ride Tracking',
    description: 'Track your ride in real-time from pickup to drop-off with our live tracking feature.',
    image: '../images/feature1.jpg',
    tags: ['Route Optimization', 'GPS Location'],
    visit: '#',
    source: '#',
  },
  {
    title: 'Seamless Integration',
    description: 'Easily pay for your rides through integrated options like signing in and signing up.',
    image: '../images/feature2.jpg',
    tags: ['User Authentication', 'Tokenization'],
    visit: '#',
    source: '#',
  },
  {
    title: 'Driver Ratings and Feedback',
    description: 'Rate your driver and provide feedback after each ride to help improve our service.',
    image: '../images/feature3.jpg',
    tags: ['App Design', 'Enhanced System'],
    visit: '#',
    source: '#',
  },
  {
    title: 'User-friendly Interface',
    description: 'Enjoy a clean and intuitive user interface that makes booking rides a breeze.',
    image: '../images/feature4.jpg',
    tags: ['UI Design', 'UX Design'],
    visit: '#',
    source: '#',
  },
];


export const TimeLineData = [
  { year: 'Real-time Ride Tracking', text: 'Track your ride in real-time from pickup to drop-off with our live tracking feature.' },
  { year: 'Seamless Payment Integration', text: 'Easily pay for your rides through integrated payment options like credit cards and digital wallets.' },
  { year: 'Driver Ratings and Feedback', text: 'Rate your driver and provide feedback after each ride to help improve our service.' },
  { year: 'User-friendly Interface', text: 'Enjoy a clean and intuitive user interface that makes booking rides a breeze.' },
];
