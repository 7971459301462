import React, { useRef, useState } from "react";
import styled from "styled-components";
import { SectionTitle } from "../../styles/GlobalComponents";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
  padding: 0 5.5rem; /* Horizontal padding added */
`;

const Title = styled.h3`
  color: #fff;
  font-size: 2.5rem; /* Large size for desktop */
  
  @media (max-width: 768px) {
    font-size: 1.8rem; /* Smaller size for mobile */
  }
`;

const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

const Input = styled.input`
  background-color: #2c3e50;
  padding: 1rem;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 1rem; /* Base font size */
  
  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smaller size for mobile */
  }
  
  &::placeholder {
    color: #95a5a6;
    font-size: 1.1rem; /* Larger placeholder font size */
    opacity: 1; /* Ensure placeholder is fully opaque */
  }
`;

const TextArea = styled.textarea`
  background-color: #2c3e50;
  padding: 1rem;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 1rem; /* Base font size */
  
  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smaller size for mobile */
  }
  
  &::placeholder {
    color: #95a5a6;
    font-size: 1.1rem; /* Larger placeholder font size */
    opacity: 1; /* Ensure placeholder is fully opaque */
  }
`;

const SubmitButton = styled.button`
  background-color: #2980b9;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 1rem; /* Base font size */
  
  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smaller size for mobile */
  }

  cursor: pointer;
  
  &:hover {
    background-color: #3498db;
  }
`;

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  return (
    <ContactContainer>
         <SectionTitle main center>Get in touch</SectionTitle>
      
      <Title>Contact.</Title>

      <form ref={formRef} style={{gap: '100px'}}>
        <InputLabel>
          Your Name
          <Input
            type="text"
            name="name"
            value={form.name}
            placeholder="What's your name?"
            onChange={(e) => setForm({ ...form, name: e.target.value })}
          />
        </InputLabel>
        <br/>
        <InputLabel>
          Your Email
          <Input
            type="email"
            name="email"
            value={form.email}
            placeholder="What's your web address?"
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        </InputLabel>
        <br/>
        <InputLabel>
          Your Message
          <TextArea
            rows={7}
            name="message"
            value={form.message}
            placeholder="What you want to say?"
            onChange={(e) => setForm({ ...form, message: e.target.value })}
          />
        </InputLabel>
       <br/>
        <SubmitButton type="submit">
          {loading ? "Sending..." : "Send"}
        </SubmitButton>
      </form>
    </ContactContainer>
  );
};

export default Contact;
