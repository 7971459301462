// src/pages/index.js
import React from 'react';
import Acomplishments from '../components/Acomplishments/Acomplishments';
import BgAnimation from '../components/BackgrooundAnimation/BackgroundAnimation';
import Contact from '../components/Contacts/contact';
import Download from '../components/Download/Download'; // Import the Download component
import Hero from '../components/Hero/Hero';
import Projects from '../components/Projects/Projects';
import Technologies from '../components/Technologies/Technologies';
import Timeline from '../components/TimeLine/TimeLine';
import { Layout } from '../layout/Layout';
import { Section } from '../styles/GlobalComponents';

const Home = () => {
    return (
      <Layout>
      <Section grid>
          <Hero />
          <BgAnimation />
      </Section>
      <div  id="features">
            <Projects />
            </div>
            <Section id="technologies">
                <Technologies />
            </Section>
            <Section id="timeline">
                <Timeline />
            </Section>
            <Section id="accomplishments">
                <Acomplishments />
            </Section>
            <Section id="download">
            <Download />
            </Section>  {/* Add the Download section here */}
            <Section id="contact">
            <Contact />
            </Section>
        </Layout>
    );
};

export default Home;
