// src/components/Download/Download.js
import React from 'react';
import styled from 'styled-components';
import { FaAppStore, FaAppStoreIos, FaGooglePlay, FaStore } from 'react-icons/fa';

const DownloadContainer = styled.div`
  background-color: #121212; // Dark background
  color: #fff; // White text
  margin: 30px;
  padding: 60px 50px;
  text-align: center;
  border-radius: 30px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
`;

const DownloadButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: red; // Custom button color
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  margin: 0 10px;
  &:hover {
    background-color: #18a05d; // Darker shade on hover
  }
`;

const GooglePlayIcon = styled(FaGooglePlay)`
  margin-right: 10px;
`;
const Appstore = styled(FaAppStore)`
  margin-right: 10px;
`;

const Download = () => {
  return (
    <DownloadContainer>
      <Title>Download Our App</Title>
      <Subtitle>
        Experience the best ride-sharing service on the go. Download Lift now!
      </Subtitle>
      <DownloadButton href="https://play.google.com/store/apps/details?id=com.lift" target="_blank">
        <GooglePlayIcon size={24} />
        Get it on Google Play
      </DownloadButton>

      <DownloadButton href="/" target="_blank">
        <Appstore size={24} />
        Get it on Appstore
      </DownloadButton>
    </DownloadContainer>
  );
};

export default Download;
